/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import WalletChecker from 'components/WalletChecker/WalletChecker';
import MITTARIA_LOGO_MOBILE from 'main/assets/images/header/logo-mobile.png';
import MITTARIA_LOGO from 'main/assets/images/header/logo.png';
import CollapseMenu from 'main/components/CollapseMenu';
import VolumeButton from 'main/components/VolumeButton';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Header(props: HeaderProps) {
  const { className } = props;
  const route = useLocation().pathname;
  const container = useRef<HTMLDivElement>(null);
  const [matches, setMatches] = useState(false);
  const [isWalletCheckerOpen, setIsWalletCheckerOpen] = useState(false);

  function closeWalletChecker() {
    setIsWalletCheckerOpen(false);
  }

  useEffect(() => {
    const media = window.matchMedia('(max-width: 1279px)');
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, setMatches]);

  return (
    <header
      ref={container}
      className={`fixed top-0 left-0 right-0 z-20 flex justify-between xl:justify-center items-center w-full h-auto p-4 md:p-8 font-monsterOfFantasy ${
        className || ''
      }`}
    >
      <nav className="relative flex-1 xl:flex hidden justify-content items-center gap-x-8 text-[14px]">
        <Link to="/" className={`uppercase ${route === '/' ? 'border-b-2' : ''}`}>
          Home
        </Link>
        <Link to="/about" className={`uppercase ${route === '/about' ? 'border-b-2' : ''}`}>
          About us
        </Link>
        <Link
          to="/new-seasons"
          className={`uppercase ${route === '/new-seasons' ? 'border-b-2' : ''}`}
        >
          Seasons
        </Link>
        <Link to="/story" className={`uppercase ${route === '/story' ? 'border-b-2' : ''}`}>
          Story
        </Link>
        <Link to="/ip" className={`uppercase ${route === '/ip' ? 'border-b-2' : ''}`}>
          IP
        </Link>
      </nav>
      <Link
        to="/"
        title="Mittaria"
        className={`flex-auto max-w-[164px] h-auto flex items-center ${
          matches ? 'justify-stary' : 'justify-center'
        }`}
      >
        <img className="hidden md:block" src={MITTARIA_LOGO} alt="Mittaria Logo" />
        <img
          className="max-h-[41px] visible md:hidden"
          src={MITTARIA_LOGO_MOBILE}
          alt="Mittaria Logo"
        />
      </Link>
      <div className="flex-1 xl:flex hidden items-center justify-end gap-x-8 text-[14px]">
        {/* <Link
          to="https://outofthenest.mittaria.io/"
          rel="noreferrer"
          target="_blank"
          className="uppercase"
        >
          Out of the nest
        </Link> */}
        <Link
          to="https://waytogods.mittaria.io/"
          rel="noreferrer"
          target="_blank"
          className="uppercase"
        >
          Way To Gods
        </Link>
        <Link
          to="https://mittaria.io/genesis"
          rel="noreferrer"
          target="_blank"
          className="uppercase"
        >
          Genesis
        </Link>
        <Link
          to="https://mittamatrix.mittaria.io/"
          rel="noreferrer"
          target="_blank"
          className="uppercase"
        >
          Mittamatrix
        </Link>
        <VolumeButton />
      </div>
      {matches && (
        <CollapseMenu
          collapseClassName="left-[-105px] origin-top-right text-sm min-w-[210px]"
          menuName="Menu"
          menuLinks={[
            {
              name: 'home',
              to: '',
            },
            // {
            //   name: 'Out of the nest',
            //   to: 'https://outofthenest.mittaria.io/',
            //   external: true,
            // },
              {
              name: 'Way To Gods',
              to: 'https://waytogods.mittaria.io/',
              external: true,
            },
            {
              name: 'Genesis',
              to: '/genesis',
              external: true,
            },
            {
              name: 'Mittamatrix',
              to: 'https://mittamatrix.mittaria.io/',
              external: true,
            },
            {
              name: 'About Us',
              to: 'about',
            },
            {
              name: 'Seasons',
              to: 'new-seasons',
            },
            {
              name: 'Story',
              to: 'story',
            },
            {
              name: 'IP',
              to: 'ip',
            },
            {
              name: 'Terms of Use',
              to: 'terms',
            },
            {
              name: 'Privacy & Policy',
              to: 'privacy',
            },
            {
              name: 'NFT Terms & Conditions',
              to: 'terms-nft',
            },
          ]}
          socialTab
          // menuChildren={
          //   <div className="cursor-pointer" onClick={openWalletChecker}>
          //     WALLET CHECKER
          //   </div>
          // }
        />
      )}

      <WalletChecker isOpen={isWalletCheckerOpen} onClose={closeWalletChecker} />
    </header>
  );
}
